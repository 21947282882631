// import React from "react";
import Head from "next/head";
import { formatTime } from "../common/function/function";
const head_seo = (props) => {
  const metaTagConfigs = [
    { name: "og:url", content: props?.url },
    { name: "og:type", content: "website" },
    {
      name: "og:music:duration",
      content: props.trackDuration
        ? formatTime(parseInt(props.trackDuration))
        : 0,
    },
    { name: "music:song", content: props.songName },
    { name: "music:musician", content: props?.artist_name },
    {
      name: "title",
      content: props?.artist_name
        ? `${props.title} - ${props.artist_name}`
        : props?.title,
    },
    {
      name: "og:title",
      content: props?.artist_name
        ? `${props.title} - ${props.artist_name}`
        : props?.title,
    },
    { name: "og:description", content: props?.desc },
    { name: "og:image:secure_url", content: props?.images },
    { name: "og:image", content: props?.images },
    { name: "og:image:width", content: "1200" },
    { name: "og:image:height", content: "630" },
    {
      name: "twitter:card",
      content: props?.embeddedUrl ? "player" : "summary_large_image",
    },
    {
      name: "twitter:title",
      content: props?.artist_name
        ? `${props.title} - ${props.artist_name}`
        : props?.title,
    },
    { name: "twitter:site", content: "@battlebards" },
    { name: "twitter:description", content: props?.desc },
    { name: "twitter:image", content: props?.images },
    {
      name: "twitter:player",
      content: props?.embeddedUrl,
      condition: props?.embeddedUrl,
    },
    {
      name: "twitter:player:width",
      content: "480",
      condition: props?.embeddedUrl,
    },
    {
      name: "twitter:player:height",
      content: "480",
      condition: props?.embeddedUrl,
    },
    { name: "twitter:url", content: props?.url },
    { name: "keywords", content: props?.keyword },
    { name: "description", content: props?.desc },
  ];

  return (
    <div>
      <Head>
        {metaTagConfigs.map((config, index) =>
          (config.condition === undefined || config.condition) &&
          config.content !== undefined &&
          config.content !== null &&
          config.content !== "" ? (
            <meta key={index} property={config.name} content={config.content} />
          ) : null
        )}
        <title>
          {props?.artist_name
            ? `${props.title} - ${props.artist_name}`
            : props?.title}
        </title>
      </Head>
    </div>
  );
};
export default head_seo;
